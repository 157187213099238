import { useState, createContext, useContext } from 'react';
import { db, auth } from './../../firebase.js';
import { collection, query, where, getDocs, limit, addDoc, updateDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';

const InferenceRecordDataWorkerContext = createContext(null);

export const useInferenceRecordDataWorker = () => {
    return useContext(InferenceRecordDataWorkerContext);
}

export const InferenceRecordDataWorkerProvider = ({ children }) => {
    const [user] = useAuthState(auth);
    const [annotationPointForRecord, setAnnotationPointForRecord] = useState({});

    const loadAnnotationPointForRecord = async (unitId, recordId) => {
        try {
            
            const userId = user.uid;
            const ref = collection(db, `/units/${unitId}/inference-records/${recordId}/point-annotations`);
            
            const q = query(ref, where("user", "==", userId), limit(1));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const doc = querySnapshot.docs[0];
                //console.log(`${doc.id} =>`, doc.data());
                const pointAnnotation = { id: doc.id, ...doc.data() };
                const annotationPointForRecordNewValue = {...annotationPointForRecord, [recordId]: pointAnnotation};
                //console.log("annotationPointForRecordNewValue", annotationPointForRecordNewValue)
                setAnnotationPointForRecord(annotationPointForRecordNewValue);

              } else {
                //console.log("No matching document found!");
              }
        } catch (e) {
            console.error(e)
        }

    }

    const uploadPointAnnotation = async (unitId, recordId, annotationPoint) => {
        const userId = user.uid;
        const pointAnnotation = {user: userId, x: annotationPoint.x, y: annotationPoint.y}

        try {
            const collectionRef = collection(db, `/units/${unitId}/inference-records/${recordId}/point-annotations`);

            const q = query(collectionRef, where("user", "==", userId), limit(1));
            //console.log("unitIdq", unitId)
            //console.log("recordIdq", recordId)
            //console.log("userIdq", userId)
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                await addDoc(collectionRef, pointAnnotation);
            } else {
                const existingRef = querySnapshot.docs[0].ref;
                await updateDoc(existingRef, pointAnnotation);
            }

        } catch (e) {
            console.error(e)
        }
    }

    return (
        <InferenceRecordDataWorkerContext.Provider value={{
            // Load
            loadAnnotationPointForRecord,

            // Get
            annotationPointForRecord,

            // Upload
            uploadPointAnnotation
        }}>
            {children}
        </InferenceRecordDataWorkerContext.Provider>
    );
}