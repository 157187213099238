import "./PointAnnotationAnimation.css";

import React, { useEffect, useState, useRef } from "react";

const PointAnnotationAnimation = () => {
    
    // Parameters
    const frameLength = 40
    const [circleCount] = useState(3);
    const [period] = useState(2500);
    const [maxRadius] = useState(150);
    
    // Animation
    const [animationFrame, setAnimationFrame] = useState(0);
    var [circles, setCircles] = useState([]);

    function useInterval(callback, delay) {
        const savedCallback = useRef();
       
        // Remember the latest callback.
        useEffect(() => {
          savedCallback.current = callback;
        }, [callback]);
       
        // Set up the interval.
        useEffect(() => {
          function tick() {
            savedCallback.current();
          }
          if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
          }
        }, [delay]);
      }

    const tickAnimationFrame = () => {
        setAnimationFrame((animationFrame + 1));
    }

    useInterval(tickAnimationFrame, frameLength);

    const drawAnimationFrame = () => {

        var circlesNewValue = []
        const minRadius = 12;
        const relativePhaseSpacing = 1 / circleCount;
        const phaseSpacing = maxRadius * relativePhaseSpacing;

        for (var i = 0; i < circles.length; i++) {
            const phaseOffset = phaseSpacing * i;

            const frequencyFactor = maxRadius / period * frameLength;
            const wavePosition = animationFrame * frequencyFactor + phaseOffset
            const relativeWavePosition = wavePosition / maxRadius
            const periodNumber = Math.floor(relativeWavePosition)
            const waveValue = wavePosition - periodNumber * maxRadius
            const circleRadiusNewValue = minRadius + waveValue

            const circleAlphaNewValue = 1 - relativeWavePosition + periodNumber;

            const circleNewValue = {radius: circleRadiusNewValue, alpha: circleAlphaNewValue};
            circlesNewValue.push(circleNewValue);
        }
        setCircles(circlesNewValue);
    }

    useEffect(() => {
        drawAnimationFrame();
    }, [animationFrame]);

    useEffect(() => {
        var circlesNewValue = []
        for (var i = 0; i < circleCount; i++) {
            const circle = {
                radius: 12,
                alpha: 1
            }
    
            circlesNewValue.push(circle);
        }

        setCircles(circlesNewValue);
    }, []);

    return (
        <div className="PointAnnotationAnimation">
            <div className="container">
                {
                circles.map((circle, index) => (
                    <div 
                    className="circle"
                    style={{
                        width: `${circle.radius}px`,
                        height: `${circle.radius}px`,
                        opacity: `${circle.alpha}`
                    }}
                    >
                    </div>
                ))
                }
                <div className="point"></div>
            </div>
        </div>
    );
}

export default PointAnnotationAnimation;