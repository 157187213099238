import "./PointButton.css";

const PointButton = () => {
    return (
        <div className="PointButton">
            <div className="circle">
                <div className="point">
                </div>
            </div>

            <div className="text">
                Udpeg objekt
            </div>
        </div>
    );
}

export default PointButton;