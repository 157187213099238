import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    "projectId": "aris-platform-dev",
    "appId": "1:808497430385:web:8debc58d87e6a5019740d0",
    "storageBucket": "aris-platform-dev.appspot.com",
    "apiKey": "AIzaSyAiQXX8XaMzE-3v-CiobKGgFgqli0JfzMw",
    "authDomain": "aris-platform-dev.firebaseapp.com",
    "messagingSenderId": "808497430385",
    "measurementId": "G-L2CY80JDMN"
};
// Initialize Firebase app
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Initialize Firebase Functions
export const functions = getFunctions(app, 'europe-west1');

// If running locally, connect to the emulator
if (process.env.NODE_ENV === 'development') {
    //connectFunctionsEmulator(functions, "localhost", 5001);
}

export default app;