import React, { createContext, useContext, useState } from 'react';
import { useUnitDataCache } from './../../../../../contexts/UnitDataCacheContext.js';

const DialogRecordContext = createContext();

export const DialogRecordProvider = ({ children, onIndexChange, index }) => {
    const [currentImgIndex, setCurrentImgIndex] = useState(0);
    const [currentRecord, setCurrentRecord] = useState(null);
    const { records } = useUnitDataCache();

    const cycleImage = (index, activeFilter) => {
        var newIndex = index;
        //console.log("Cycle image", newIndex, activeFilter);
        const filteredRecords = records.filter((elem) => activeFilter === '' ? true : elem.eval === activeFilter);

        if (newIndex < 0) {
            newIndex = 0;
        } else if (newIndex >= filteredRecords.length) {
            newIndex = filteredRecords.length - 1;
        }

        setCurrentImgIndex(newIndex);
        setCurrentRecord(filteredRecords[newIndex]);

        // Call the callback to notify about the index change
        if (onIndexChange) {
            onIndexChange(newIndex);
        }
    };

    return (
        <DialogRecordContext.Provider value={{ currentImgIndex, currentRecord, cycleImage }}>
            {children}
        </DialogRecordContext.Provider>
    );
};

export const useDialogRecord = () => useContext(DialogRecordContext);
