import { useState, useEffect, useRef } from 'react';
import "./BarChart.css";

const BarChart = ({ data, graphMaxValue = undefined, colors, barMargin = 5 }) => {
    const [dataValueOfTopOfChart, setDataValueOfTopOfChart] = useState(0)
    const [barSums, setBarSums] = useState([])
    const [chartHeight, setChartHeight] = useState(0)
    const [chartWidth, setChartWidth] = useState(0)
    const outerRef = useRef()
    
    useEffect(() => {
        const outerRefCurrent = outerRef.current;
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setChartHeight(entry.contentRect.height)
                setChartWidth(entry.contentRect.width)
            }
        })

        if (outerRefCurrent) {
            resizeObserver.observe(outerRefCurrent);
        }
    
        return () => {
            if (outerRefCurrent) {
                resizeObserver.unobserve(outerRefCurrent);
            }
        };
    }, [])
    
    useEffect(() => {
        var highestDataValue = 0
        var barSumsNewValue = []

        for (var barIndex = 0; barIndex < data.length; barIndex++) {
            var sum = 0
            for (var segmentIndex = 0; segmentIndex < data[barIndex].length; segmentIndex++) {
                sum += data[barIndex][segmentIndex]
            }
            if (sum > highestDataValue) {
                highestDataValue = sum
            }
            barSumsNewValue.push(sum)
        }
        if (graphMaxValue === undefined) {
            setDataValueOfTopOfChart(highestDataValue)
        }
        setBarSums(barSumsNewValue)

    }, [data])

    useEffect(() => {
        if (graphMaxValue !== undefined) {
            setDataValueOfTopOfChart(graphMaxValue)
        }
    }, [graphMaxValue])

    const getBarWidth = () => {
        return chartWidth / data.length - barMargin * 2
    }

    return (
        <div 
            className="BarChart"
            ref={outerRef}
        >
            {dataValueOfTopOfChart
                ? 
                    data.map((bar, barIndex) => (
                        <div 
                            key={barIndex}
                            className="bar-column"
                        >
                            <div 
                                className="bar"
                                style={{ 
                                    "height": `${barSums[barIndex] / dataValueOfTopOfChart * chartHeight + Math.max(0, getBarWidth() / 2 - (barSums[barIndex] / dataValueOfTopOfChart * chartHeight))}px`,
                                    "marginLeft": barMargin,
                                    "marginRight": barMargin,
                                    position: "relative",
                                    "top": Math.max(0, getBarWidth() / 2 - (barSums[barIndex] / dataValueOfTopOfChart * chartHeight))
                                }}
                            >
                                <div 
                                    className="colored-area"
                                    style={{height: barSums[barIndex] / dataValueOfTopOfChart * chartHeight}}
                                >
                                    {barSums[barIndex] > 0 && bar.map((segment, segmentIndex) => (
                                        <div 
                                            key={segmentIndex}
                                            className={(segmentIndex === 0) ? "top-segment" : "segment"}
                                            style={{ 
                                                "backgroundColor": colors[segmentIndex], 
                                                "height": `${segment / barSums[barIndex] * 100}%`
                                            }}
                                        >
                                        </div>
                                    ))}
                                </div>
                                <div 
                                    className="filler-area"
                                    style={{height: Math.max(0, getBarWidth() / 2 - (barSums[barIndex] / dataValueOfTopOfChart * chartHeight))}}
                                >
                                </div>
                            </div>
                        </div>
                    ))
                : 
                    <></>
            }
        </div>
    );
}

export default BarChart;