import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './../../firebase';
import { useUser } from './../../contexts/UserContext.js';
import LoginPage from "./../../pages/LoginPage/LoginPage.jsx"

const PrivateRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  const { userData } = useUser();

  if (loading || (user && !userData)) {
    return <div>Loading...</div>;
  }

  return user ? children : <LoginPage />;
};

export default PrivateRoute;