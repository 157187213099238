import React, { createContext, useState, useContext } from 'react';

// Create the context
const ThirdHeaderContext = createContext();

// Create a provider component
export const ThirdHeaderProvider = ({ children }) => {
    const [showUserEvaluations, setShowUserEvaluations] = useState(false);

    return (
        <ThirdHeaderContext.Provider value={{
            showUserEvaluations,
            setShowUserEvaluations
        }}>
            {children}
        </ThirdHeaderContext.Provider>
    );
};

export const useThirdHeader = () => {
    return useContext(ThirdHeaderContext);
};
