import { createContext, useContext } from 'react';
import { useInferenceRecordDataWorker } from './InferenceRecordDataWorkerContext.js';

const InferenceRecordDataParserContext = createContext(null);

export const useInferenceRecordDataParser = () => {
    return useContext(InferenceRecordDataParserContext);
}


export const InferenceRecordDataParserProvider = ({ children }) => {
    const { annotationPointForRecord } = useInferenceRecordDataWorker();

    return (
        <InferenceRecordDataParserContext.Provider value={{
            annotationPointForRecord
        }}>
            {children}
        </InferenceRecordDataParserContext.Provider>
    );
}