import React, { useEffect, useState, useCallback } from 'react';
import { Tooltip } from 'react-tooltip';
import { useSecondHeader } from './../../contexts/SecondHeaderContext';
import { useUnitDataCache } from './../../contexts/UnitDataCacheContext';
import { useUnitDataParser } from './../../contexts/UnitDataParserContext';
import './WadeReportWeekPage.css'; // Import the corresponding CSS
import questionCircle from "./../../assets/icons/question-circle.svg"
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import increase_icon from "./../../assets/icons/increase_icon.svg"
import decrease_icon from "./../../assets/icons/decrease_icon.svg"
import DonutChart from '../../components/DonutChart/DonutChart.jsx';
import ActivationsOverTimeChart from '../../components/ActivationsOverTimeChart/ActivationsOverTimeChart.jsx';


const WadeReportWeekPage = () => {
    const location = useLocation();

    const getMondayOfCurrentWeek = () => {
        const today = new Date();
        const dayOfWeek = today.getDay(); // 0 (Sun) to 6 (Sat)
        const diff = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek; // Calculate the difference to Monday
        const monday = new Date(today.setDate(today.getDate() + diff));
        monday.setHours(0, 0, 0, 0); // Set time to the start of the day
        return monday;
    }

    const { t } = useTranslation();
    const { unitId } = useParams();

    const [colors] = useState([
        '#74D5F2',
        '#BD92F4',
        '#81F3D5',
        '#FF8989',
        '#81ECF3',
        '#53C0FF',
        '#ED9BD2',
        '#FF89B0',
        '#90AAFF',
        '#81F3AD'
    ]);

    const year_start_unix = new Date(new Date().getFullYear(), 0, 1).getTime();
    const now_unix = new Date().getTime();
    const activationsFromDefault = year_start_unix;
    const activationsToDefault = now_unix;
    const activationsPrevFromDefault = new Date(new Date().getFullYear() - 1, 0, 1).getTime();
    const activationsPrevToDefault = new Date(new Date().getFullYear() - 1, 11, 31).getTime();
    const [activationsFrom, setActivationsFrom] = useState(activationsFromDefault);
    const [activationsTo, setActivationsTo] = useState(activationsToDefault);
    const [activationsPrevFrom, setActivationsPrevFrom] = useState(activationsPrevFromDefault);
    const [activationsPrevTo, setActivationsPrevTo] = useState(activationsPrevToDefault);

    // Default heatmap to show the current week
    const activityHeatmapFromDefault = getMondayOfCurrentWeek().getTime();
    const activityHeatmapToDefault = now_unix;
    const [activityHeatmapFrom, setActivityHeatmapFrom] = useState(activityHeatmapFromDefault);
    const [activityHeatmapTo, setActivityHeatmapTo] = useState(activityHeatmapToDefault);

    const activityOverTimeFromDefault = year_start_unix;
    const activityOverTimeToDefault = now_unix;
    const activityOverTimeUnitDefault = "week";
    const [activityOverTimeFrom, setActivityOverTimeFrom] = useState(activityOverTimeFromDefault);
    const [activityOverTimeTo, setActivityOverTimeTo] = useState(activityOverTimeToDefault);
    const [activityOverTimeUnit, setActivityOverTimeUnit] = useState(activityOverTimeUnitDefault);

    const [timeUnit, setTimeUnit] = useState('uge'); // Default to 'år' (year)

    const [activationsUnit, setActivationsUnit] = useState('week');

    const [isWadeReportMode, setIsWadeReportMode] = useState(false);
    const [isWadeReportTimeMonth, setIsWadeReportTimeMonth] = useState(null);

    const [dountValues, setDonutValues] = useState([]);

    const {
        activationsCounts,
        activationsTotal,
        activationsRelativePercentage,
        activityOverTimeData,
        getActivationsData,
        getActivityOverTimeData,
        getActivityHeatmapData
    } = useUnitDataCache(); 

    const { 
        heatmapData
    } = useUnitDataParser();

    const { currentUnit, getUnitData } = useSecondHeader();


    useEffect(() => {
        const isWadeReportMode = true;
        const wadeReportTimeIsMonth = true;
        setIsWadeReportMode(isWadeReportMode)
        setIsWadeReportTimeMonth(wadeReportTimeIsMonth)

        const startOfLastCalendarMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).getTime();
        const endOfLastCalendarMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime();

        const mondayOfCurrentWeek = getMondayOfCurrentWeek();
        const startOfLastWeek = new Date(mondayOfCurrentWeek.getFullYear(), mondayOfCurrentWeek.getMonth(), mondayOfCurrentWeek.getDate() - 7).getTime();
        var endOfLastWeekDate = new Date(mondayOfCurrentWeek)
        // Set time to beginning of day
        endOfLastWeekDate.setHours(0, 0, 0, 0);
        const endOfLastWeek = endOfLastWeekDate.getTime();
    

        setTimeUnit('uge');
        setActivationsUnit('week');
        setActivityOverTimeUnit('week');

        setActivityHeatmapFrom(startOfLastWeek);
        setActivityHeatmapTo(endOfLastWeek);

        setActivationsFrom(startOfLastWeek);
        setActivationsTo(endOfLastWeek);
        
        setActivityOverTimeFrom(startOfLastWeek);
        setActivityOverTimeTo(endOfLastWeek);

        activationsDateSelected(startOfLastWeek, endOfLastWeek, 'week');
        activationsDateSelected(startOfLastWeek, endOfLastWeek, 'week');
        
    }, [location])

    useEffect(() => {
        setDonutValues(activationsCounts.map((category, index) => {return category.count}))

        const indexOfUndefined = activationsCounts.findIndex((category) => category.category.includes('UNDEFINED'));
        if (indexOfUndefined !== -1) {
            colors.splice(indexOfUndefined, 0, '#A5A5A5');
        }
    }, [activationsCounts])

    const fetchHeatmapData = async () => {
        //console.log("heatMap- fetchHeatmapData");
        //console.log("heatMap- Current unit:", currentUnit);
        //console.log("heatMap- From:", activityHeatmapFrom);
        if (!currentUnit) return;

        try {
            await getActivityHeatmapData(unitId, activityHeatmapFrom, activityHeatmapTo);
        } catch (error) {
            console.error('Error fetching activity heatmap data:', error);
        }
    }


    // Convert hex color to RGB
    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        return {
            r: (bigint >> 16) & 255,
            g: (bigint >> 8) & 255,
            b: bigint & 255,
        };
    };

    const fetchActivityData = async () => {
        //console.log("activations- Fetching activity data...");
        //console.log("activations- Current unit:", currentUnit);
        if (!currentUnit) return;
        await getActivationsData(unitId, activationsFrom, activationsTo, activationsPrevFrom, activationsPrevTo);
    }

    const fetchActivityOverTimeData = async () => {
        //console.log("activityOverTime- Fetching activity over time data...");
        //console.log("activityOverTime- Current unit:", currentUnit);
        //console.log("activityOverTime- From:", activityOverTimeFrom);
        //console.log("activityOverTime- To:", activityOverTimeTo);
        if (!currentUnit || (activityOverTimeFrom === undefined) || (activityOverTimeTo === undefined) || activityOverTimeUnit === undefined) return;
        try {
            await getActivityOverTimeData(unitId, activityOverTimeFrom, activityOverTimeTo, activityOverTimeUnit);
        } catch (error) {
            console.error('activityOverTime-- Error fetching activity over time data:', error);
        }
    }

    // On mount
    useEffect(() => {
        //console.log("Fetching data... (on mount)");

        if (!currentUnit) {
            //console.log("Fetching unit data...", unitId);
            //console.log("Unit id:")
            //console.log(unitId)
            getUnitData(unitId)
        }

    }, []);

    useEffect(() => {
        fetchActivityData();

        const intervalId = setInterval(async () => {
            fetchActivityData();
        }, 10000);

        return () => {
            intervalId && clearInterval(intervalId);
        }
    }, [currentUnit, activationsFrom, activationsTo, activationsPrevFrom, activationsPrevTo]);
    
    useEffect(() => {
        fetchHeatmapData();

        const heatMapInterval = setInterval(async () => {
            fetchHeatmapData();
        }, 10000);

        return () => {
            heatMapInterval && clearInterval(heatMapInterval);
        }
    }, [currentUnit, activityHeatmapFrom, activityHeatmapTo]);

    useEffect(() => {
        //console.log("activityOverTime- useEffect, time unit", activityOverTimeUnit)
        fetchActivityOverTimeData();

        const intervalId = setInterval(async () => {
            fetchActivityOverTimeData();
        }, 10000);

        return () => {
            intervalId && clearInterval(intervalId);
        }
    }, [currentUnit, activityOverTimeFrom, activityOverTimeTo, activityOverTimeUnit]);

    useEffect(() => {
        if (activityOverTimeData) {
            const barChartData = activityOverTimeData.map((category, i) => {
                const itemStyle = category.category.includes('UNDEFINED')
                    ? { color: '#A5A5A5' }
                    : { color: colors[i] };

                return {
                    data: category.counts.map(val => ({ value: val })),
                    name: category.category,
                    type: 'bar',
                    stack: 'a',
                    barMaxWidth: '50%',
                    itemStyle,
                };
            }).reverse();

            addBarBorderRadius(barChartData);
        }
    }, [currentUnit, activityOverTimeData, colors]);


    const addBarBorderRadius = (barChartData) => {
        for (let dataIndex = 0, moreValue = true; moreValue; dataIndex++) {
            moreValue = false;
            for (let i = 0; i < barChartData.length; i++) {
                const data = barChartData[i].data;
                if (dataIndex < data.length) {
                    moreValue = true;
                    if (data[dataIndex].value !== 0) {
                        data[dataIndex] = { value: data[dataIndex].value };
                        break;
                    }
                }
            }
        }

        for (let dataIndex = 0, moreValue = true; moreValue; dataIndex++) {
            moreValue = false;
            for (let i = barChartData.length - 1; i >= 0; i--) {
                const data = barChartData[i].data;
                if (dataIndex < data.length) {
                    moreValue = true;
                    if (data[dataIndex].value !== 0) {
                        data[dataIndex] = {
                            value: data[dataIndex].value,
                            itemStyle: {
                                borderRadius: [50, 50, 0, 0],
                            },
                        };
                        break;
                    }
                }
            }
        }
    };

    const activationsDateSelected = async (start_unix, end_unix, time_unit) => {

        setActivationsFrom(start_unix);
        setActivationsTo(end_unix);

        const previousPeriodStart = new Date(start_unix);
        previousPeriodStart.setFullYear(previousPeriodStart.getFullYear() - (time_unit === "year" ? 1 : 0));
        previousPeriodStart.setMonth(previousPeriodStart.getMonth() - (time_unit === "month" ? 1 : 0));
        previousPeriodStart.setDate(previousPeriodStart.getDate() - (time_unit === "week" ? 7 : 0));

        // Calculate the end of the previous period so it is has the same length as the current period
        const prevEnd = new Date(end_unix);
        prevEnd.setFullYear(prevEnd.getFullYear() - (time_unit === "year" ? 1 : 0));
        prevEnd.setMonth(prevEnd.getMonth() - (time_unit === "month" ? 1 : 0));
        //console.log("prev- prev end 1", prevEnd)
        prevEnd.setDate(prevEnd.getDate() - (time_unit === "week" ? 7 : 0));

        setActivationsPrevFrom(previousPeriodStart.getTime());
        //console.log("prev- prev end", prevEnd)
        setActivationsPrevTo(prevEnd.getTime())

        setActivationsUnit(time_unit);
    };

    
    const getCategoryLabel = useCallback((category) => {
        return t(category);
    }, [t]);

    const sumArray = (array) => array.reduce((acc, curr) => acc + curr, 0);

    const formatDateForDateLine = (date) => {

        // Create an options object for the custom format
        const options = { day: 'numeric', month: 'long', year: 'numeric' };

        // Format the date using the specified locale and options
        const formattedDate = new Intl.DateTimeFormat('da-DK', options).format(date);

        return formattedDate
    }

    const getDateLineString = (from, to) => {
        return `${formatDateForDateLine(new Date(from))} - ${formatDateForDateLine(new Date(to))}`
    }

    const activityHeatmapUnit = 'uge';

    const activityHeatmapTotals = heatmapData.length > 0
    ? heatmapData[0].counts.map((_, colIndex) =>
       heatmapData.reduce((total, category) => total + category.counts[colIndex], 0)
    )
    : [];

    const getAvgCountsHeatmap = (counts) => {
        if (activityHeatmapUnit === 'uge') {
            
            // Count whole week, unless week has not ended yet
            const today = new Date();
            const dayOfWeek = today.getDay(); // 0 (Sun) to 6 (Sat)
            const mondayIndexed = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // 0 (Mon) to 6 (Sun)
    
            // Get the start of the current week (Sunday)
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - mondayIndexed);
            // Set time to the start of the day
            startOfWeek.setHours(0, 0, 0, 0);

            // Check if the given date is in the current week
            var weekBound = 7
            const heatMapDate = new Date(activityHeatmapFrom)
            if (heatMapDate >= startOfWeek && heatMapDate <= today) {
                weekBound = mondayIndexed + 1; // Days passed in the current week, including today
            } 

            const validCounts = counts.slice(0, 7); // Assuming 'uge' means we only care about the first 7 days

            const sum = validCounts.reduce((acc, count) => acc + count, 0);
            if (validCounts.length === 0) {
                return 0;
            }
            return Math.round(sum / weekBound);
        }
        return 0;
    };

    const lightenColor = (color, factor) => {
        const colorObj = hexToRgb(color);
        const r = Math.round(colorObj.r + (255 - colorObj.r) * factor);
        const g = Math.round(colorObj.g + (255 - colorObj.g) * factor);
        const b = Math.round(colorObj.b + (255 - colorObj.b) * factor);
        return `rgb(${r},${g},${b})`;
    };

    const getShade = (baseColor, count, maxCount) => {
        if (count === 0) return 'white';
        const lightenFactor = (1 - count / maxCount) * 1;
        return lightenColor(baseColor, lightenFactor);
    };

    const daysOfWeek = ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Sat', 'Sun'];

    return (
        <div className="WadeReportWeekPage">
        <div className="date-line">{getDateLineString(activityOverTimeFrom, activityOverTimeTo)}</div>
            <div className="wrapper">
                <div className="grid-wrapper">
                    <div className="row">
                        <div className="card-container activity-card">
                            <div className="card-header">
                                <div className="card-header-title">
                                    Ugentlig aktivitet
                                </div>
                                <div className="card-header-actions">
                                </div>
                            </div>
                            <div className="card-container activity-card">
                                <div className="card-body activity-wrapper" style={{ height: '100%' }}>
                                    
                                    <div className="activity-headers">
                                        <div className="activity-header"></div>
                                        {daysOfWeek.map((day, index) => (
                                            <div key={index} className="activity-header">{t(day)}</div>
                                        ))}
                                        {activityHeatmapUnit === 'uge' && (
                                            <div className="activity-header" style={{ color: 'var(--gray-500)' }}>Gns</div>
                                        )}
                                    </div>
                                    
                                    <div className="activity-averages">
                                        <div className="activity-cell"></div>
                                        
                                        {activityHeatmapTotals.map((total, index) => (
                                            <div
                                                key={index}
                                                className="activity-cell activity-cell-data"
                                                style={{ backgroundColor: total === 0 ? 'white' : "var(--success-400)" }} // Default color for totals
                                            >
                                                { total }
                                            </div>
                                        ))}
                                            
                                        {activityHeatmapUnit === 'uge' && (
                                            <div
                                                className="activity-cell activity-cell-data"
                                                style={{ backgroundColor: 'var(--success-400)' }} // Gray color for average
                                            >
                                                {getAvgCountsHeatmap(activityHeatmapTotals)}
                                            </div>
                                        )}
                                            
                                    </div>
                                    
                                    {heatmapData.map((category, rowIndex) => {
                                        const maxCount = Math.max(...category.counts);
                                        const baseColor = category.category === "UNDEFINED" ? '#A5A5A5' : colors[rowIndex % colors.length];

                                        return (
                                            <div key={rowIndex} className="activity-categories">
                                                <div className="activity-cell">{getCategoryLabel(category.category)}</div>
                                                {category.counts.map((dayCount, colIndex) => (
                                                    <div
                                                        key={colIndex}
                                                        className="activity-cell activity-cell-data"
                                                        style={{ backgroundColor: getShade(baseColor, dayCount, maxCount) }}
                                                    >
                                                        {dayCount}
                                                    </div>
                                                ))}
                                                {activityHeatmapUnit === 'uge' && (
                                                    <div
                                                        className="activity-cell activity-cell-data"
                                                        style={{ backgroundColor: baseColor }}
                                                    >
                                                        {getAvgCountsHeatmap(category.counts)}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="barchart-wrapper">
                                <div className="card-container barchart-card">
                                    <div className="card-header">
                                        <div className="card-header-title">
                                            Aktiveringer over tid
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <ActivationsOverTimeChart 
                                            activityOverTimeData={activityOverTimeData} 
                                            startDate={activityOverTimeFrom}
                                            colors={colors}
                                            chartHeight={324}
                                            timeUnit='week'
                                        />
                                    </div>
                                </div>
                            </div>
                            
                        <div className="card-container donut-card-wade-report">
                            <div className="card-header">
                                <div className="card-header-title">Kategorifordeling</div>
                            </div>
                            <div className="donut-section-body-wade-report-month">
                                <DonutChart absoluteValues={dountValues} outerRadius={144} innerRadius={76} colors={colors}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WadeReportWeekPage;
