import "./DonutChart.css";

const DonutChart = ({absoluteValues, outerRadius = 120, innerRadius = 60, segmentGap = 4, cornerRadius = 4, colors}) => {

    const getPathDataForSegment = (angleRange) => {
        console.log("getPathDataForSegment--- ")
        console.log("angleRange--- ", angleRange)
        var pathData = ""

        const circleCenterX = outerRadius
        const circleCenterY = outerRadius

        const x1 = circleCenterX + innerRadius * Math.cos(Math.PI / 2 - (angleRange.startAngle + Math.atan(cornerRadius / innerRadius)))
        const y1 = circleCenterY - innerRadius * Math.sin(Math.PI / 2 - (angleRange.startAngle + Math.atan(cornerRadius / innerRadius)))
        const x2 = circleCenterX + (innerRadius + cornerRadius) * Math.cos(Math.PI / 2 - angleRange.startAngle)
        const y2 = circleCenterY - (innerRadius + cornerRadius) * Math.sin(Math.PI / 2 - angleRange.startAngle)
        const x3 = circleCenterX + (outerRadius - cornerRadius) * Math.cos(Math.PI / 2 - angleRange.startAngle)
        const y3 = circleCenterY - (outerRadius - cornerRadius) * Math.sin(Math.PI / 2 - angleRange.startAngle)
        const x4 = circleCenterX + outerRadius * Math.cos(Math.PI / 2 - (angleRange.startAngle + Math.atan(cornerRadius / outerRadius)))
        const y4 = circleCenterY - outerRadius * Math.sin(Math.PI / 2 - (angleRange.startAngle + Math.atan(cornerRadius / outerRadius)))
        const x5 = circleCenterX + outerRadius * Math.cos(Math.PI / 2 - (angleRange.endAngle - 2 * Math.asin((segmentGap / 2) / outerRadius) - Math.atan(cornerRadius / outerRadius)))
        const y5 = circleCenterY - outerRadius * Math.sin(Math.PI / 2 - (angleRange.endAngle - 2 * Math.asin((segmentGap / 2) / outerRadius) - Math.atan(cornerRadius / outerRadius)))
        const x6 = circleCenterX + (outerRadius - cornerRadius) * Math.cos(Math.PI / 2 - (angleRange.endAngle - 2 * Math.asin((segmentGap / 2) / (outerRadius - cornerRadius))))
        const y6 = circleCenterY - (outerRadius - cornerRadius) * Math.sin(Math.PI / 2 - (angleRange.endAngle - 2 * Math.asin((segmentGap / 2) / (outerRadius - cornerRadius))))
        const x7 = circleCenterX + (innerRadius + cornerRadius) * Math.cos(Math.PI / 2 - (angleRange.endAngle - 2 * Math.asin((segmentGap / 2) / (innerRadius + cornerRadius))))
        const y7 = circleCenterY - (innerRadius + cornerRadius) * Math.sin(Math.PI / 2 - (angleRange.endAngle - 2 * Math.asin((segmentGap / 2) / (innerRadius + cornerRadius))))
        const x8 = circleCenterX + innerRadius * Math.cos(Math.PI / 2 - (angleRange.endAngle - 2 * Math.asin((segmentGap / 2) / innerRadius) - Math.atan(cornerRadius / innerRadius)))
        const y8 = circleCenterY - innerRadius * Math.sin(Math.PI / 2 - (angleRange.endAngle - 2 * Math.asin((segmentGap / 2) / innerRadius) - Math.atan(cornerRadius / innerRadius)))

        pathData += `M ${x1} ${y1} `
        pathData += `A ${cornerRadius} ${cornerRadius} 0 0 1 ${x2} ${y2} `
        pathData += `L ${x3} ${y3} `
        pathData += `A ${cornerRadius} ${cornerRadius} 0 0 1 ${x4} ${y4} `

        const largArcFlagOuter = (angleRange.endAngle - angleRange.startAngle) > Math.PI ? 1 : 0
        pathData += `A ${outerRadius} ${outerRadius} 0 ${largArcFlagOuter} 1 ${x5} ${y5} `

        pathData += `A ${cornerRadius} ${cornerRadius} 0 0 1 ${x6} ${y6} `
        pathData += `L ${x7} ${y7} `
        pathData += `A ${cornerRadius} ${cornerRadius} 0 0 1 ${x8} ${y8} `

        const largArcFlagInner = (angleRange.endAngle - angleRange.startAngle) > Math.PI ? 1 : 0
        pathData += `A ${innerRadius} ${innerRadius} 0 ${largArcFlagInner} 0 ${x1} ${y1} `

        pathData += "Z"
        return pathData
    }

    const chartComponent = () => {
        console.log("chartComponent--- ")
        console.log("absoluteValues--- ", absoluteValues)
        var sum = 0
        var angleRanges = []
        var percentages = []
        var labelCoordinates = []

        for(var i = 0; i < absoluteValues.length; i++) {
            sum += absoluteValues[i]
        }

        for(var j = 0; j < absoluteValues.length; j++) {
            const percentage = (absoluteValues[j] / sum) * 100
            percentages.push(percentage.toFixed(1) + "%")

            const startAngle = (j === 0) ? 0 : angleRanges[j - 1].endAngle
            const endAngle = startAngle + (absoluteValues[j] / sum) * Math.PI * 2
            const angleRange = {
                startAngle: startAngle,
                endAngle: endAngle
            }
            angleRanges.push(angleRange)

            const labelAngle = startAngle + (endAngle - startAngle) / 2
            const labelX = outerRadius + Math.sin(labelAngle) * (innerRadius + (outerRadius - innerRadius) / 2)
            const labelY = outerRadius - Math.cos(labelAngle) * (innerRadius + (outerRadius - innerRadius) / 2)
            labelCoordinates.push({x: labelX, y: labelY})
        }

        //console.log("Chart svg--- ")

        return (
            <>
                <svg width={outerRadius * 2} height={outerRadius * 2}> {
                    angleRanges.map((angleRange, segmentIndex) => {
                        return (
                            <path key={segmentIndex} d={getPathDataForSegment(angleRange)} fill={colors[segmentIndex % colors.length]}/>
                        )
                    })
                } </svg>
                <div className="value-labels">
                    {
                        percentages.map((value, index) => {
                            return (
                                <div 
                                    key={index} 
                                    className="value-label-wrapper"
                                    style={{
                                        position: "absolute",
                                        top: labelCoordinates[index].y,
                                        left: labelCoordinates[index].x,
                                    }}
                                >
                                    <div className="value-label">
                                        {value}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        )
    }

    return (
        <div className="DonutChart">
            {chartComponent()}
        </div>
    );
}

export default DonutChart;