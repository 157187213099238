import "./AppFooter.css";
import logo from "./../../../assets/icons/aris_insights.svg";
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from "react"

const AppFooter = () => {
	const location = useLocation();
	const [isWadeReportMode, setIsWadeReportMode] = useState(false);

	useEffect(() => {
		location.pathname.includes('wade-report-mode') && setIsWadeReportMode(true)
    }, [location])


	return (
		<div 
			className="app-footer"
			style={{
				"borderTop": isWadeReportMode ? "none" : "1px solid var(--gray-700)"
			}}
		>
			{isWadeReportMode
			? <>
				<p className="text-wade-report-mode"> ARIS Robotics ApS</p>
				<p className="text-wade-report-mode"> +45 22 37 01 25 </p>
				<p className="text-wade-report-mode"> info@aris-robotics.com</p>
			</>
			: 
			<>
				<img
					className="logo"
					src={logo}
					alt="Aris Insights" 
					style={{"cursor": "pointer"}}
				/>
				<p className="text">
					© 2024 ARIS Robotics. All rights reserved.
				</p>
			</>
			}
		</div>
	)
}

export default AppFooter;