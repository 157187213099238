import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './contexts/UserContext.js';
import { SecondHeaderProvider } from './contexts/SecondHeaderContext.js';
import { ThirdHeaderProvider } from './contexts/ThirdHeaderContext.js';
import { UnitDataCacheProvider } from './contexts/UnitDataCacheContext';
import { UnitDataParserProvider } from './contexts/UnitDataParserContext.js';
import './i18n';
import { InferenceRecordDataWorkerProvider } from './contexts/InferenceRecordData/InferenceRecordDataWorkerContext.js';
import { InferenceRecordDataParserProvider } from './contexts/InferenceRecordData/InferenceRecordDataParserContext.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <UserProvider>
        <SecondHeaderProvider>
            <ThirdHeaderProvider>
                <UnitDataCacheProvider>
                    <UnitDataParserProvider>
                        <InferenceRecordDataWorkerProvider>
                            <InferenceRecordDataParserProvider>
                                <App />
                            </InferenceRecordDataParserProvider>
                        </InferenceRecordDataWorkerProvider>
                    </UnitDataParserProvider>
                </ UnitDataCacheProvider>
            </ThirdHeaderProvider>
        </SecondHeaderProvider>
    </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
