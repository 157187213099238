import React, { useCallback, useEffect, useState, useRef } from "react";
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from './../../../../../firebase.js';
import { useUnitDataCache } from './../../../../../contexts/UnitDataCacheContext.js';
import { useSecondHeader } from './../../../../../contexts/SecondHeaderContext.js';
import { auth } from './../../../../../firebase.js';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useUser } from './../../../../../contexts/UserContext.js';

import left_arrow from "./../../../../../assets/icons/left_arrow.svg"
import right_arrow from "./../../../../../assets/icons/right_arrow.svg"
import data_error_svg from "./../../../../../assets/icons/data_error.svg"
import add_button_icon from "./../../../../../assets/icons/add-button.svg"
import circleCursorIcon from "./../../../../../assets/icons/circleCursorIcon.svg"

import { useTranslation } from "react-i18next";

import "./RecordDialog.css";
import PointButton from "./PointButton/PointButton.jsx";
import { useInferenceRecordDataWorker } from "../../../../../contexts/InferenceRecordData/InferenceRecordDataWorkerContext.js";
import { useInferenceRecordDataParser } from "../../../../../contexts/InferenceRecordData/InferenceRecordDataParserContext.js";
import { useUnitDataParser } from "../../../../../contexts/UnitDataParserContext.js";
import PointAnnotationAnimation from "./PointAnnotationAnimation/PointAnnotationAnimation.jsx";

const RecordDialog = ({isOpen, initialIndex, onUserEvalChange, onNewCategorySubmitted, localRecords, specified_colors}) => {
    const { t } = useTranslation();

    const [user, loading] = useAuthState(auth);
    const { userData }  = useUser();
    const [currentRecordX, setCurrentRecordX] = useState(null);
    const [currentRecordIndex, setCurrentRecordIndex] = useState(null);
    const currentRecordIndexRef = useRef(currentRecordIndex)
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadFailed, setLoadFailed] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const { records, setCustomEval, getOtherUserInfo, removeCustomEval, addCustomCategory, removeCustomEvalCategory } = useUnitDataCache();
    const { currentUnit } = useSecondHeader();
    const { getColorForCategory, localCustomEvalCategories, setLocalCustomEvalCategories } = useUnitDataParser();
    const [user_info, setUser_info] = useState({});
    const [localUserEvals, setLocalUserEvals] = useState({});
    const [isEdittingCategories, setIsEdittingCategories] = useState(false);
    const [isEnteringNewCategory, setIsEnteringNewCategory] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const inputRef = useRef(null);
    const spanRef = useRef(null);
    const imgRef = useRef(null);
    const annotationPointButtonRef = useRef(null);
    const [currentInputWidth, setCurrentInputWidth] = useState(0);

    const [isSettingPoint, setIsSettingPoint] = useState(false);
    const [annotationPointXLocal, setAnnotationPointXLocal] = useState(null);
    const [annotationPointYLocal, setAnnotationPointYLocal] = useState(null);
    const annotationPointXLocalRef = useRef(annotationPointXLocal);
    const annotationPointYLocalRef = useRef(annotationPointYLocal);
    const [annotationPointXLocalScaled, setAnnotationPointXLocalScaled] = useState(null);
    const [annotationPointYLocalScaled, setAnnotationPointYLocalScaled] = useState(null);
    
    const { uploadPointAnnotation, loadAnnotationPointForRecord } = useInferenceRecordDataWorker();
    const { annotationPointForRecord } = useInferenceRecordDataParser()
    const [showingAnnotationPointAnimation, setShowingAnnotationPointAnimation] = useState(false);
    const showingAnnotationPointAnimationRef = useRef(showingAnnotationPointAnimation);

    const handleUserDidSetPoint = (x, y) => {
        const unitId = currentUnit.unit_id;
        if (!currentRecordX) {
            return
        }
        uploadPointAnnotation(unitId, currentRecordX.record_id, {x: x, y: y})
    }

    const autoStartSettingPoint = () => {
        setIsSettingPoint(true);
        setShowingAnnotationPointAnimation(true)
    }

    const handleMouseMove = (event) => {
        if(showingAnnotationPointAnimationRef.current === true) {
            if (imgRef.current) {
                const imgBoundingBox = imgRef.current.getBoundingClientRect();
                const x = event.clientX;
                const y = event.clientY;
                const imgX = imgBoundingBox.x;
                const imgY = imgBoundingBox.y;
                const imgWidth = imgBoundingBox.width;
                const imgHeight = imgBoundingBox.height;
                if ( x > imgX && x < imgX + imgWidth && y > imgY && y < imgY + imgHeight) {
                    setShowingAnnotationPointAnimation(false)
                }
            }
        }
    }

    const handleMouseDown = (event) => {
        if (imgRef.current) {
            var isSettingPointNewValue = isSettingPoint;
            const insideButton = annotationPointButtonRef.current && annotationPointButtonRef.current.contains(event.target)
            if (isSettingPoint) {
                isSettingPointNewValue = false;
    
                const x = event.clientX;
                const y = event.clientY;
                
                const imgBoundingBox = imgRef.current.getBoundingClientRect();
                const imgX = imgBoundingBox.x;
                const imgY = imgBoundingBox.y;
                const imgWidth = imgBoundingBox.width;
                const imgHeight = imgBoundingBox.height;
    
                if ( x > imgX && x < imgX + imgWidth && y > imgY && y < imgY + imgHeight) {
                    const xLocalScaled = x - imgX;
                    const yLocalScaled = y - imgY;
                    const xLocal = xLocalScaled / imgWidth;
                    const yLocal = yLocalScaled / imgHeight;
                    
                    setAnnotationPointXLocal(xLocal);
                    setAnnotationPointYLocal(yLocal);
    
                    handleUserDidSetPoint(xLocal.toFixed(5), yLocal.toFixed(5));
                }
            } else {
                if (insideButton) {
                    isSettingPointNewValue = true;
                }
            }
    
            setIsSettingPoint(isSettingPointNewValue);
        }
    }

    const handleResize = () => {
        if (imgRef.current) {
            if (annotationPointXLocalRef.current && annotationPointYLocalRef.current) {
                setAnnotationPointXLocalScaled(annotationPointXLocalRef.current * imgRef.current.getBoundingClientRect().width);
                setAnnotationPointYLocalScaled(annotationPointYLocalRef.current * imgRef.current.getBoundingClientRect().height);
            }
        }
    }

    useEffect(() => {
        if (isSettingPoint) {
            document.body.style.cursor = `url(${circleCursorIcon}) 0 0, auto`;
        } else {
            document.body.style.cursor = `auto`;
            setShowingAnnotationPointAnimation(false)
        }
    }, [isSettingPoint]);

    useEffect(() => {
        if (currentRecordX) {
            loadAnnotationPointForRecord(currentUnit.unit_id, currentRecordX.record_id);
        }
    }, [currentRecordX]);

    useEffect(() => {
        if (annotationPointForRecord && currentRecordX && imgRef.current) {
            const currentRecordPoint = annotationPointForRecord[currentRecordX.record_id];
            if (currentRecordPoint) {
                setAnnotationPointXLocal(currentRecordPoint.x);
                setAnnotationPointYLocal(currentRecordPoint.y);
            } else {
                setAnnotationPointXLocal(null);
                setAnnotationPointYLocal(null);
            }
        }
    }, [annotationPointForRecord, currentRecordX]);

    useEffect(() => {

        document.addEventListener('mousedown', handleMouseDown);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        }
    }, [isSettingPoint]);

    useEffect(() => {
        window.addEventListener('keydown', keyDown);
        document.addEventListener("mousemove", handleMouseMove)
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('keydown', keyDown);
            document.removeEventListener("mousemove", handleMouseMove)
            window.removeEventListener('resize', handleResize);
        }
    }, []);


    useEffect(() => {
        if (isOpen) {
            if (currentRecordX && currentRecordX.img_name) {
                loadImage();
            }
        }
    }, [isOpen, currentRecordX]);

    useEffect(() => {
        if (currentRecordX) {
            if (currentRecordX.user_evals) {
                getUserInfo();
            } else {
                setLocalUserEvals({});
            }
        }
    }, [currentRecordX]);



    const adjustInputWidth = () => {
        if (spanRef.current) {
            const spanWidth = spanRef.current.offsetWidth;
            setCurrentInputWidth(spanWidth + 2)
        }
    }
    useEffect(() => {
        // Adjust the width of the input field based on the width of the text in the span
        adjustInputWidth();
      }, [inputValue]);

    const loadImage = async () => {
        
        if (!currentRecordX) {
            return
        }
        
        setLoadFailed(false);
        const imgRef = ref(storage, `images/${currentRecordX.img_name}`);
        try {
            const url = await getDownloadURL(imgRef);
            setImageUrl(url);
            setLoadingPage(false);
        } catch (error) {
            setLoadFailed(true);
            setLoadingPage(false);
        }
    };

    const voteCategoryPressed = (category) => {
        console.log("voteCategoryPressed")
        // If the user has already voted for this category, remove their vote
        if (localUserEvals && (localUserEvals[user.uid] === category)) {
        //if (localUserEvals[user.uid] === category) {
          removeVote();
          onUserEvalChange(currentRecordX.record_id, null);
        } else {
          voteCategory(category);
          onUserEvalChange(currentRecordX.record_id, category);
        }

    }

    const removeVote = async () => {
        try {
            // Call to database to remove the user evaluation
            await removeCustomEval(currentRecordX, currentUnit.unit_id, user.uid);

            const localUserEvalsNewValue = { ...localUserEvals };

            delete localUserEvalsNewValue[user.uid];

            setLocalUserEvals(
                localUserEvalsNewValue
            );

        } catch (error) {
            console.error('Error removing custom evaluation:', error);
        }
    }

    const voteCategory = async (evaluation) => {
        try {
          // Call to database to update the user evaluation
            setLocalUserEvals({
                ...localUserEvals, 
                [user.uid]: evaluation
            });

            setCustomEval(currentRecordX, evaluation, currentUnit.unit_id, user.uid);
        } catch (error) {
          console.error('Error setting custom evaluation:', error);
        }
    };

    const getUserInfo = async () => {
        if (currentRecordX?.user_evals) {
            try {
                //let result = await this.data_cache.testUserInfo(ids)

                let result = await getOtherUserInfo(Object.keys(currentRecordX.user_evals));
                if (result) {
                    // Convert to dictionary with user ID as key
                    var dictionary = {};
                    result.forEach(user => {
                        dictionary[user.id] = user;
                    });
                    setUser_info(dictionary);
                    setLocalUserEvals(currentRecordX.user_evals);
                } else {
                    setUser_info({});
                }
            } catch (error) {
                // User does not exist
            }
        }
    }

    const getUserVoteIDs = useCallback((category) => {
        if (!localUserEvals) {
            return [];
        }
    
        const ids = Object.keys(localUserEvals).filter(key => localUserEvals[key] === category);
        // If the current user has voted, move their ID to the front of the list
        const currentUser = userData;
        if (currentUser) {
            const index = ids.indexOf(currentUser.uid);
            if (index > -1) {
                ids.splice(index, 1); // Remove the user's ID from its original position
                ids.unshift(currentUser.uid); // Add the user's ID to the front
            }
        }
        return ids;
    }, [localUserEvals, userData]);

    const vizTime = () => {
        if (!currentRecordX || !currentRecordX.timestamp) {
            return '';
        }
    
        const date = new Date(currentRecordX.timestamp);
    
        const dateOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };
        
        const dayOption = {
            weekday: 'long'
        }
    
        const timeOptions = {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }
          
        const dateSegment = new Intl.DateTimeFormat('da-DK', dateOptions).format(date);
        
        // Reformatting to "YYYY-MM-DD"
        const [day, month, year] = dateSegment.split('.');
        const formattedDateISO = `${year}-${month}-${day}`;
      
        var daySegment = new Intl.DateTimeFormat('da-DK', dayOption).format(date);
        daySegment = daySegment.charAt(0).toUpperCase() + daySegment.slice(1);
        
        const timeSegment = new Intl.DateTimeFormat('da-DK', timeOptions).format(date).replace(/\./g, ":");
        
        const formattedDate = `${formattedDateISO} | ${daySegment} | ${timeSegment}`;

        return formattedDate;
    };

    const getColorForUser = (userId) => {
        const color = userDataForId(userId)["color"]
        return color ? color: "#eb6e65"
    }

    const newCategoryPressed = () => {
        if (isEnteringNewCategory) {
            newCategorySubmitted();
            setInputValue(null)
            adjustInputWidth();
            setIsEnteringNewCategory(false);
        } else {
            setInputValue(null)
            adjustInputWidth();
            setIsEnteringNewCategory(true);
            adjustInputWidth();
        }
    } 

    const keyDown = (event) => {
        if(event.key === 'ArrowRight') {
            goToNextRecord()
        } else if(event.key === 'ArrowLeft') {
            goToPreviousRecord()
        }

    }

    const inputKeyDown = (event) => {
        if (event.key === 'Enter') {
            newCategorySubmitted();
            setInputValue(null)
            adjustInputWidth();
            setIsEnteringNewCategory(false);
        }

    }

    const inputOnBlur = () => {
        newCategorySubmitted();
        setInputValue(null)
        adjustInputWidth();
        setIsEnteringNewCategory(false);
    }

    const newCategorySubmitted = () => {
        console.log("debug 17")
        const newCategory = inputValue;
        if (newCategory && newCategory.length !== 0 && !localCustomEvalCategories.includes(newCategory)) {
            autoStartSettingPoint();
            setLocalUserEvals({
                ...localUserEvals,
                [user.uid]: newCategory
            });
            setCustomEval(currentRecordX, newCategory, currentUnit.unit_id, user.uid);
            var localCustomEvalCategoriesNewValue = [...localCustomEvalCategories]
            localCustomEvalCategoriesNewValue.push(newCategory)
            setLocalCustomEvalCategories(localCustomEvalCategoriesNewValue);

            // Update remote
            addCustomCategory(currentRecordX, newCategory, currentUnit.unit_id)

            onNewCategorySubmitted(currentRecordX.record_id, newCategory);
        }
    }

    const deleteCategoryClicked = (index) => {
        const category = localCustomEvalCategories[index]
        const localCustomEvalCategoriesNewValue = localCustomEvalCategories
        localCustomEvalCategoriesNewValue.splice(index, 1)
        setLocalCustomEvalCategories(localCustomEvalCategoriesNewValue);
        removeCustomEvalCategory(currentRecordX, category, currentUnit.unit_id)
    }

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    const userDataForId = (id) => {
        if (user_info[id]) {
            return user_info[id]
        } else if (id === user.uid) {
            return userData
            
        }

        return null
    }

    const pointButtonClicked = () => {
    }

    const goToNextRecord = () => {
        if (currentRecordIndexRef.current != null && currentRecordIndexRef.current > 0) {
            setCurrentRecordIndex(currentRecordIndexRef.current - 1)
        }
    }
    
    const goToPreviousRecord = () => {
        if (currentRecordIndexRef.current != null && currentRecordIndexRef.current < localRecords.length - 1) {
            setCurrentRecordIndex(currentRecordIndexRef.current + 1)
        }
    }

    // MARK: - useEffects
    useEffect(() => {
        if ((initialIndex != null) && (currentRecordIndex == null)) {
            setCurrentRecordIndex(initialIndex);
        }
    }, [initialIndex]);

    useEffect(() => {
        currentRecordIndexRef.current = currentRecordIndex;
    }, [currentRecordIndex]);

    useEffect(() => {
        showingAnnotationPointAnimationRef.current = showingAnnotationPointAnimation;
    }, [showingAnnotationPointAnimation]);

    useEffect(() => {
        annotationPointXLocalRef.current = annotationPointXLocal;
    }, [annotationPointXLocal]);

    useEffect(() => {
        annotationPointYLocalRef.current = annotationPointYLocal;
    }, [annotationPointYLocal]);

    useEffect(() => {
        if ((currentRecordIndex != null) && localRecords && (currentRecordIndex < localRecords.length)) {
            setCurrentRecordX(localRecords[currentRecordIndex]);
        }
    }, [currentRecordIndex, localRecords])

    useEffect(() => {
        if (imgRef.current) {
            setAnnotationPointXLocalScaled(annotationPointXLocal * imgRef.current.getBoundingClientRect().width);
        }
    }, [annotationPointXLocal]);

    useEffect(() => {
        if (imgRef.current) {
            setAnnotationPointYLocalScaled(annotationPointYLocal * imgRef.current.getBoundingClientRect().height);
        }
    }, [annotationPointYLocal]);

    return (
        <div 
            className="RecordDialog"
        >
            {currentRecordX && currentUnit &&
                <div className="dialog">
                    <div className="img-wrapper">
                        <div className="image-header">Billede</div>
                        
                        <div className="img-inner-wrapper" style={{ justifyContent: loadFailed || loading ? 'center' : 'flex-start' }}>
                            {!loadingPage && !loadFailed && 
                            <img 
                                ref={imgRef} 
                                className="inference-img" 
                                src={imageUrl} 
                                alt="" 
                            />}
                            {loadFailed && (
                                <>
                                <img src={data_error_svg} width="80" height="80" alt="Error loading" />
                                <div className="no-img">Billede kunne ikke indlæses</div>
                                </>
                            )}
                            {/*loading && (
                                <div className="loading-img-wrapper">
                                <img className="loading-img" src={loading_spinner} width="80" height="80" alt="Loading" />
                                </div>
                            )*/}

                            <div 
                                className="annotationPointAnimation" 
                                style={{
                                    visibility: showingAnnotationPointAnimation ? "visible" : "hidden"
                                }}
                            > {showingAnnotationPointAnimation &&
                                <PointAnnotationAnimation />
                            }
                            </div>

                            <div 
                                className="annotationPoint" 
                                style={{
                                    left: annotationPointXLocalScaled, 
                                    top: annotationPointYLocalScaled,
                                    visibility: annotationPointXLocalScaled && annotationPointYLocalScaled ? "visible" : "hidden"
                                }}>
                            </div>
                        </div>

                        <div className="cycle-btns">
                            <div
                                className="cycle-btn"
                                onClick={goToPreviousRecord}
                                style={currentRecordIndex >= localRecords.length - 1 ? { opacity: 0.4, cursor: 'default' } : {}}
                                >
                                <img src={left_arrow} alt="Forrige billede" />
                            </div>
                            <div className="image-label">{vizTime()}</div>
                            <div
                                className="cycle-btn"
                                onClick={goToNextRecord}
                                style={currentRecordIndex < 1 ? { opacity: 0.4, cursor: 'default' } : {}}
                            >
                                <img src={right_arrow} alt="Næste billede" />
                            </div>
                        </div>
                    </div>

                    <div className="info-wrapper">
                        <div className="record-info-box">
                            <div className="evaluation-header">Evaluering</div>
                            <div
                                className="record-info-value"
                                style={{
                                color: getColorForCategory(currentRecordX.eval),
                                }}
                            >
                                {t(currentRecordX.eval)}
                            </div>
                        </div>

                        <div className="user-evaluations-title">Brugerevaluering</div>
                        <div className="category-section">
                            <div className="scrollable-wrapper">
                                <div className="category-buttons">

                                    {currentUnit.eval_categories.map((category, i) => (
                                        <React.Fragment key={i}>
                                            <div
                                                key={`category-${category}`}
                                                className="category-button"
                                                onClick={() => voteCategoryPressed(category)}
                                                style={{
                                                    color: getColorForCategory(category),
                                                }}
                                            >
                                                {t(category)}
                                            </div>
                                            <div className="user-category-votes-container">    
                                                <div key={`votes-container-${category}`} className="user-category-votes">
                                                {getUserVoteIDs(category).map((_user, j) => ( userDataForId(_user) &&
                                                    <div
                                                    key={`user-${_user}`}
                                                    className={`user-vote icon-text ${_user === user.uid ? 'current-user-vote' : ''}`}
                                                    style={{ backgroundColor: getColorForUser(_user) }}
                                                    title={userDataForId(_user)["name"]}
                                                    >
                                                    {userDataForId(_user)["name"].slice(0, 2).toUpperCase()}
                                                    </div>
                                                ))}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                    {localCustomEvalCategories.map((category, i) => (
                                        <React.Fragment key={i}>
                                            <div
                                                key={i * 6 + 3}
                                                className="user-added-category-button"
                                                onClick={() => voteCategoryPressed(category)}
                                                style={{
                                                    color: (category in specified_colors) ? specified_colors[category] : getColorForCategory(category)
                                                }}
                                            >
                                                {category}
                                                { isEdittingCategories ?
                                                <div className="categoryDeleteButton" onClick={()=>{deleteCategoryClicked(i)}}>{"🚫"}</div>
                                                : <></>
                                                }
                                            </div>
                                            <div key={i * 6 + 4} className="user-category-votes">
                                            {getUserVoteIDs(category).map((_user, j) => ( userDataForId(_user) &&
                                                <div
                                                key={j * 6 + 5}
                                                className={`user-vote icon-text ${_user === user.uid ? 'current-user-vote' : ''}`}
                                                style={{ backgroundColor: getColorForUser(_user) }}
                                                title={userDataForId(_user)["name"]}
                                                >
                                                {userDataForId(_user)["name"].slice(0, 2).toUpperCase()}
                                                </div>
                                            ))}
                                            </div>
                                            </React.Fragment>
                                    ))}
                                    <div className="category-input-wrapper">
                                        <span
                                            ref={spanRef}
                                            style={{
                                            position: "absolute",
                                            visibility: "hidden",
                                            whiteSpace: "pre",
                                            }}
                                        >
                                            {inputValue || "Ny..."}
                                        </span>
                                        {isEnteringNewCategory? 
                                            <div 
                                                className="category-button"
                                            >
                                                <input 
                                                    id="new-category-input" 
                                                    type="text" 
                                                    placeholder="Ny..." 
                                                    ref={inputRef}
                                                    value={inputValue}
                                                    onKeyDown={inputKeyDown}
                                                    onChange={handleChange}
                                                    onBlur={inputOnBlur}
                                                    style={{
                                                        margin: "0px",
                                                        minWidth: "0",
                                                        width: `${currentInputWidth}px`,
                                                        padding: "0px",
                                                        boxSizing: "border-box",
                                                        border: "1px solid transparent", // Consistent border, no changes on focus
                                                        outline: "none",           // Prevent the focus outline from appearing
                                                    }}
                                                    autoFocus
                                                />
                                            </div>
                                        : 
                                            <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="floating-section">
                                <div
                                    className="add-category-button"
                                    onClick={newCategoryPressed}
                                >
                                    <img 
                                        src={add_button_icon}
                                        alt="Add" 
                                    />
                                </div>

                                <div 
                                    className="setPointButton"
                                    onClick={pointButtonClicked}
                                    ref={annotationPointButtonRef}
                                    style={{
                                        cursor: isSettingPoint ? "inherit" : "pointer"
                                    }}
                                >
                                    <PointButton />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default RecordDialog;